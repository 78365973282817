<template>
  <div id="app">
    <div class="radial">
      <img src="./assets/images/radial.png" alt="a green blurry circle" />
    </div>
    <div id="nav">
      <router-link class="nav-link logo-link" to="/"
        >Naël<span class="star">✹</span>Fliti</router-link
      >
      <router-link class="nav-link" to="/about">About</router-link>
      <router-link class="nav-link" to="/contact">Contact</router-link>
    </div>
    <router-view />
    <footer>
      <div class="right">
        <p>naël fliti © 2022 | all rights reserved</p>
        <img src="./assets/images/icons/smiley.svg" alt="a drawing of a blue smiley" />
      </div>
      <div class="coord">
        <a
          target="blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/naelfliti/"
          >instagram</a
        >
        <a
          target="blank"
          rel="noopener noreferrer"
          href="https://linkedin/in/naelfliti"
          >linkedin</a
        >
        <a
          target="blank"
          rel="noopener noreferrer"
          href="https://twitter.com/FlitiNael"
          >twitter</a
        >
        <a
          target="blank"
          rel="noopener noreferrer"
          href="mailto:nael.fliti@gmail.com"
          >mail</a
        >
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: "VarentGrotesk";
  src: url("./assets/fonts/VarentGrotesk-Bold.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "Agrandir";
  src: url("./assets/fonts/Agrandir.woff2") format("woff2-variations");
  font-display: swap;
}

@font-face {
  font-family: "VarentGrotesk";
  src: url("./assets/fonts/VarentGrotesk-ExtLtIta.woff2") format("woff2");
  font-weight: 100;
}

html,
body {
  font-size: 67.5%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-transform:translateZ(0);
  transform:translateZ(0);
}

body {
  background: #F7F5F3 ;
}

.radial {
  height: 60vh;
  width: 60vh;
  position: absolute;
  top: 0;
  //border-radius: 50%;
  //background: $green;
  z-index: -1;
  //filter: blur(70px);
  overflow:hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

#nav {
  position: fixed;
  top: 0;
  padding: 1rem 2rem;
  z-index: 9;
}

p,
a {
  font-family: $font-body;
  font-size: 1.6rem;
  color: black;
  margin: 0;
}

hr {
  margin: 0;
  border: none;
}

h1 {
  font-family: $font-title;
  font-size: 26rem;
  line-height: 100%;
  margin: 0;
  @media screen and (max-width: 800px) {
    font-size: 12rem;
  }
}

a {
  text-decoration: none;
}

.logo-link {
  &.router-link-exact-active {
    span {
      color: $green;
    }
    &:after {
      display: none;
    }
  }
}

.nav-link {
  text-transform: lowercase;
  position: relative;
  margin: 0 1rem;
  padding: 0 0.2rem;
  &:before {
    content: "";
    transform: scaleX(100%);
    display: block;
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    height: 0.4rem;
    z-index: -1;
    background: $green;
    transition: transform 0.2s ease-in-out;
    transform-origin: left;
  }
  &:hover {
    &:before {
      transform: scaleX(0%);
    }
  }
  &.router-link-exact-active {
    &:after {
      content: "";
      position: absolute;
      left: -10%;
      padding: 0.2rem;
      bottom: -20%;
      width: 130%;
      height: 130%;
      z-index: -1;
      transform: rotate(-5deg);
      background: url("./assets/images/icons/link-active.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}
.right {
  margin: 1rem 2rem;
  display: flex;
  align-items: flex-end;
  position: relative;
  img {
    margin-left: 0.5rem;
    width: 4rem;
    left: 100%;
    bottom: -20%;
    position: absolute;
  }
  @media screen and (max-width: 800px) {
    margin: 0;
  }
}

.coord {
  padding: 1rem 2rem;
  a:not(a:last-child) {
    border-right: 1px solid black;
  }
  a {
    padding: 0 0.7rem;
    position: relative;
    &:before {
      content: "";
      transform: scaleX(0%);
      display: block;
      position: absolute;
      left: 0;
      top: 40%;
      width: 100%;
      height: 0.4rem;
      z-index: -1;
      background: $green;
      transition: transform 0.2s ease-in-out;
      transform-origin: left;
    }
    &:hover {
      &:before {
        transform: scaleX(100%);
      }
    }
  }
}
</style>

<script>
import Radial from "@/assets/class/Radial.js";
import Cartapus from 'cartapus'



export default {
  name: "Home",
  components: {
  },
  mounted() {
    Radial.init(this.$el);
    const cartapus = new Cartapus({
      root: null,
      rootMargin: '0px',
      threshold: 0.5 ,
      once: true,
      events: true
    })

    console.log(cartapus)
  },
  updated() {
    console.log(`At this point, Virtual DOM has not re-rendered or patched yet.`)
    Radial.init(this.$el);
  }
};
</script>
