<template>
  <div class="centered">
    <h2 data-cartapus>
      I’m just a french child from the internet who like to design and code some
      stuffs on the web
    </h2>
  </div>
</template>

<script>
export default {
  name: "Centered",
};
</script>

<style scoped lang="scss">
.centered {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
}

h2 {
  font-family: $font-title;
  font-weight: 100;
  font-size: 5rem;
  width: 70%;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 4rem;
    width: 100%;
  }

  &[data-cartapus]{
    /* HIDDEN STATE */
    opacity: 0;
    transition: opacity 1s;
  }

  &[data-cartapus=visible]{
    /* VISIBLE STATE */
    opacity: 1;
  }
}
</style>
