<template>
  <div class="work-holder">
    <div class="work section2">
      <h3>my<span class="star">✹</span>works</h3>
      <div class="works">
        <div class="work-image-holder">
          <transition name="fade">
            <img
                v-if="active === 1"
                src="../assets/images/projects/bfc-market.png"
                alt="the ui of the bfc market project"
                class="work-image"
            />
          </transition>

          <transition name="fade">
            <img
                v-if="active === 2"
                src="../assets/images/projects/sogelac.png"
                alt="the ui of the sogelac project"
                class="work-image"
            />
          </transition>

          <transition name="fade">
            <img
                v-if="active === 3"
                src="../assets/images/projects/mercedes.jpg"
                alt="the ui of the mercedes project"
                class="work-image"
            />
          </transition>

          <transition name="fade">
            <img
                v-if="active === 4"
                src="../assets/images/projects/vdd.jpg"
                alt="the ui of the vdd project"
                class="work-image"
            />
          </transition>

          <transition name="fade">
            <img
                v-if="active === 5"
                src="../assets/images/projects/efor.png"
                alt="the ui of the efor project"
                class="work-image"
            />
          </transition>
        </div>
        <div class="work-link-holder">
          <!-- BFC Market -->
          <div class="work-link" v-on:mouseover="active = 1">
            <div class="index">
              <p class="number">001</p>
              <span class="blue-dot"></span>
              <p class="date-mobile no-desktop">jan-mar 2021</p>
            </div>
            <div class="title-project">
              <h6>BFC Market</h6>
              <span class="blue-dot no-mobile"></span>
              <div class="date">
                <p>jan-mar 2021</p>
                <router-link to="/projects/bfc-market">
                  see the project
                  <img src="../assets/images/icons/arrow.svg" alt="arrow icon"/>
                </router-link>
              </div>
            </div>
          </div>
          <!-- DS Auto -->
          <div class="work-link" v-on:mouseover="active = 2">
            <div class="index">
              <p class="number">002</p>
              <span class="blue-dot"></span>
              <p class="date-mobile no-desktop">nov-dec 2021</p>
            </div>
            <div class="title-project">
              <h6>Sogelac</h6>
              <span class="blue-dot no-mobile"></span>
              <div class="date">
                <p>nov-dec 2021</p>
                <router-link to="/projects/sogelac">
                  see the project
                  <img src="../assets/images/icons/arrow.svg" alt="arrow icon"/>
                </router-link>
              </div>
            </div>
          </div>
          <!-- Mercedes -->
          <div class="work-link" v-on:mouseover="active = 3">
            <div class="index">
              <p class="number">003</p>
              <span class="blue-dot"></span>
              <p class="date-mobile no-desktop">apr 2021</p>
            </div>
            <div class="title-project">
              <h6>Mercedes</h6>
              <span class="blue-dot no-mobile"></span>
              <div class="date">
                <p>apr 2021</p>
                <router-link to="/projects/mercedes">
                  see the project
                  <img src="../assets/images/icons/arrow.svg" alt="arrow icon"/>
                </router-link>
              </div>
            </div>
          </div>
          <!-- Vie-nyle DD -->
          <div class="work-link" v-on:mouseover="active = 4">
            <div class="index">
              <p class="number">004</p>
              <span class="blue-dot"></span>
              <p class="date-mobile no-desktop">jan 2022 - now</p>
            </div>
            <div class="title-project">
              <h6>Vie-nyle DD</h6>
              <span class="blue-dot no-mobile"></span>
              <div class="date">
                <p>jan 2022 - now</p>
                <router-link to="/projects/vdd">
                  see the project
                  <img src="../assets/images/icons/arrow.svg" alt="arrow icon"/>
                </router-link>
              </div>
            </div>
          </div>
          <!-- Efor -->
          <div class="work-link" v-on:mouseover="active = 5">
            <div class="index">
              <p class="number">005</p>
              <span class="blue-dot"></span>
              <p class="date-mobile no-desktop">feb-may 2022</p>
            </div>
            <div class="title-project">
              <h6>Efor Group</h6>
              <span class="blue-dot no-mobile"></span>
              <div class="date">
                <p>feb-may 2022</p>
                <router-link to="/projects/efor">
                  see the project
                  <img src="../assets/images/icons/arrow.svg" alt="arrow icon"/>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Work",
  data: function () {
    return {
      active: 1
    };
  }
  // mounted: function () {
  //   this.startSlide();
  // },
  // methods: {
  //   onWorkLinkHover: function () {
  //     console.log('on y est')
  //   },
  // }
};
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: all .3s ease-out;
}

.fade-enter, .fade-leave-to
{
  opacity: 0;
  transform: scale(1.2);
}

.work-holder {
  height: 100vh;
  display: block;
}

.index {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;
  @media screen and (max-width: 800px) {
    width: 70%;
    max-width: 23rem;
  }
}

.title-project {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 80%;
  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: space-between;
  }
}

.blue-dot {
  width: 2rem;
  height: 2rem;
  background: $blue;
  display: block;
  border-radius: 50%;
  @media screen and (max-width: 800px) {
    width: 1rem;
    height: 1rem;
  }
}

h3 {
  font-family: $font-title;
  font-size: 17vw;
  line-height: 100%;
  margin: 0;
  border-bottom: 2px solid black;
  margin-bottom: 2rem;
  @media screen and (max-width: 800px) {
    text-align: center;
  }
  @media screen and (min-width: 1300px) {
    font-size: 23rem;
  }

  .star {
    color: $green;
    font-size: 15vw;
    @media screen and (min-width: 1300px) {
      font-size: 21rem;
    }
  }
}

.works {
  display: flex;
  position: relative;
}

.no-mobile {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.no-desktop {
  @media screen and (min-width: 800px) {
    display: none;
  }
}

.work-image-holder {
  width: 33%;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.work-link-holder {
  margin-left: 3rem;
  width: 100%;
  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
}

.work-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 2rem 0;
  max-height: 7rem;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .number {
    font-size: 5rem;
    font-family: $font-title;
    font-weight: 600;
    width: 10%;
    @media screen and (max-width: 800px) {
      font-size: 4rem;
      width: unset;
    }
  }

  h6 {
    width: 50%;
    font-family: $font-title;
    font-size: 4.5vw;
    margin: 0;
    white-space: nowrap;
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    @media screen and (max-width: 800px) {
      font-size: 5rem;
      width: 100%;
    }
  }

  .date {
    text-align: end;

    p {
      font-variation-settings: "wght" 300;
      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      @media screen and (max-width: 800px) {
        visibility: hidden;
        flex-direction: row-reverse;
        position: absolute;
        left: 90%;
        bottom: 100%;
      }

      &:hover {
        color: $blue;

        img {
          filter: brightness(1);
        }
      }

      img {
        margin-left: 1rem;
        filter: brightness(0);
        @media screen and (max-width: 800px) {
          visibility: visible;
        }
      }

      font-size: 2rem;
    }
  }
}
</style>
