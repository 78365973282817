import {gsap} from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class Radial {
  constructor() {
      this.bind()
      this.section1
      this.section2
      this.radial
  }

  init(view) {
    this.section1 = view.querySelector('.section1')
    this.section2 = view.querySelector('.section2')
    this.radial = view.querySelector('.radial')

    // home - center
    ScrollTrigger.create({
      trigger: this.section1,
      start: 'top top',
      end: 'bottom center',
      // markers:1,
      onLeave: ()=>{
        gsap.to(this.radial, {x:'50vw', y:`${this.section2.getBoundingClientRect().y}px`, duration:1})
      },
      onEnterBack:()=>{
        gsap.to(this.radial, {x:'0', y:'0', duration:1})
      }
    })

    // console.log(this.work.getBoundingClientRect())

    // work - footer
    // ScrollTrigger.create({
    //   trigger: this.work,
    //   start: 'top top',
    //   end: 'bottom center',
    //   markers:1,
    //   onEnter: ()=>{
    //     gsap.to(this.radial, {x:`${this.work.getBoundingClientRect().x}px`, y:`${this.work.getBoundingClientRect().y}px`, duration:1})
    //   },
    //   // onEnterBack:()=>{
    //   //   gsap.to(this.radial, {x:'0', y:'0', duration:1})
    //   // }
    // })
  }

  update() {

  }

  bind() {

  }
}

const _instance = new Radial()
export default _instance
