import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/projects/bfc-market',
    name: 'BFCMarket',
    component: () => import('../views/projects/BFCMarket.vue')
  },
  {
    path: '/projects/ds-automobile',
    name: 'DSAuto',
    component: () => import('../views/projects/DSAuto.vue')
  },
  {
    path: '/projects/sogelac',
    name: 'Sogelac',
    component: () => import('../views/projects/Sogelac.vue')
  },
  {
    path: '/projects/mercedes',
    name: 'Mercedes',
    component: () => import('../views/projects/Mercedes.vue')
  },
  {
    path: '/projects/vdd',
    name: 'VDD',
    component: () => import('../views/projects/VDD.vue')
  },
  {
    path: '/projects/efor',
    name: 'Efor',
    component: () => import('../views/projects/Efor.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
