<template>
  <div class="contact" data-cartapus>
    <h2>
      Feel free to drop me a message
      <img class="arrow-contact" src="../assets/images/icons/arrow-contact.svg" alt="a handwritten blue arrow">
      </h2>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped lang="scss">
.contact {
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-cartapus]{
    /* HIDDEN STATE */
    opacity: 0;
    transition: opacity 1s;
  }

  &[data-cartapus=visible]{
    /* VISIBLE STATE */
    opacity: 1;
  }
}

h2 {
  position: relative;
  font-weight: 400;
  line-height: 100%;
  font-size: 5rem;
  width: max-content;
  text-align: center;
  font-family: $font-body;
  @media screen and (max-width: 800px) {
    font-size: 4rem;
  }
}

.arrow-contact{
  position: absolute;
  top: 70%;
  left: 100%;
  @media screen and (max-width: 800px) {
    display: none;
  }
}
</style>
