<template>
  <div class="home-wrapper">
    <div class="home section1">
      <div class="title-holder">
        <h1 data-cartapus>
          <span class="upper-line title-line">
            <img src="../assets/images/icons/nael.svg" alt="Naël in typo" />
          </span>
          <span class="lower-line title-line">
            <img src="../assets/images/icons/fliti.svg" alt="Fliti in typo" />
            <span>22 years old french creative developer</span>
          </span>
        </h1>
      </div>
    </div>
    <centered></centered>
    <work></work>
    <contact></contact>
  </div>
</template>

<style lang="scss" scoped>
.home-wrapper {
  padding: 0 2rem;
  @media screen and (max-width: 800px) {
    padding: 0 2rem;
  }
}

.home {
  margin-top: 5rem;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title-holder {
  position: relative;
  margin-left: 5%;
  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
}

h1 {
  font-family: $font-title;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  width: 100%;

  img {
    width: 70%;
    max-width: 150rem;
  }

  //&[data-cartapus]{
  //  .title-line {
  //    img {
  //      transform: translateY(150%);
  //    }
  //  }
  //}
  .title-line {
    overflow: hidden;
  }

  .lower-line{
    overflow: hidden;
    img {
      @media screen and (min-width: 1400px) {
        width: 70%;
      }
    }
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1400px) {
      flex-direction: row;
      align-items: flex-end;
    }

    span:last-child{
      @media screen and (min-width: 1400px) {
        margin-left: 5rem;
        width: min-content;
      }

      @media screen and (min-width: 800px) {
        font-size: 5rem;
      }
      font-family: $font-body;
      font-size: 3rem;
      margin-top: 3rem;
      line-height: 100%;
    }
  }
  span {
    display: block;
    text-align: start;
    width: 100%;
    @media screen and (max-width: 800px) {
      text-align: start;
    }
  }
}

</style>

<script>
import Centered from "@/components/Centered.vue";
import Work from "@/components/Work.vue";
import Contact from "@/components/Contact.vue";
// import {gsap} from 'gsap'

export default {
  name: "Home",
  components: {
    Centered,
    Work,
    Contact
  },
  // mounted: function () {
  //   this.onIntersect();
  // },
  // methods : {
  //   onIntersect : function (){
  //     const el = document.querySelector('h1')
  //       el.addEventListener('cartapusintersect', ({ detail }) => {
  //         const img = el.querySelectorAll('.title-line img')
  //         if(detail.visible){
  //           gsap.to(
  //               img,{
  //               yPercent : 0,
  //                 duration : 1,
  //                 ease : 'power3.out'
  //             }
  //           )
  //         }
  //         if(!detail.visible){
  //           gsap.to(
  //               img,{
  //                 yPercent : 150,
  //                 duration : 1,
  //                 ease : 'power3.out'
  //               }
  //           )
  //         }
  //       })
  //   }
  // }
};
</script>
